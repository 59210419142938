﻿import {Action, Malle} from '@deltablot/malle'
import 'bootstrap'
import 'datatables.net-bs4'
import 'jquery'
import 'jquery-validation'
import 'jquery-validation-unobtrusive'

(function ($) {
    'use strict';

    /*
     * jQuery unobtrusive validation additions
     */
    {
        $.validator.addMethod('not-null-or-empty-or-whitespace', function (value, _, __) {
            return !(value == null || value.trim() === '');
        });

        $.validator.unobtrusive.adapters.addBool('not-null-or-empty-or-whitespace');
    }

    /*
     * Secure submit buttons by displaying overlay on submit
     */
    {
        (function () {
            window.confirmSubmit = function (message) {
                if (confirm(message)) {
                    $('#loading').css('display', 'block');
                    return true;
                }

                return false;
            };

            $('form:not([data-confirm-submit])')
                .on('submit', function () {
                    $('#loading').css('display', 'block');
                })
                .bind("invalid-form.validate", function () {
                    $('#loading').css('display', 'none');
                });
        }());
    }

    /*
     * Tooltips
     */
    {
        (function () {
            $('.tip').each(function () {
                $(this).tooltip({
                    html: true,
                    title: $('#' + $(this).data('tip')).html(),
                    sanitize: false
                });
            });
        }());
    }

    /*
     * Copy to clipboard buttons
     */
    {
        (function () {
            $('.copy-to-clipboard').click(function () {
                let button = $(this);
                navigator.clipboard
                    .writeText($(`#${$(this).data('copy-to-clipboard')}`).text())
                    .then(
                        _ => {
                            $(button).fadeOut({
                                duration: 300,
                                complete: function () {
                                    $(button).show();
                                }
                            });
                        },
                        _ => {
                            console.error('Failed to copy to clipboard');
                        }
                    );
            })
        })();
    }

    /*
     * Generic DataTable initialization
     */
    {
        (function () {
            $('.dataTable').each(function () {
                $(this).DataTable({
                    lengthMenu: [5, 10, 25, 100]
                });
            });
        })();
    }

    /*
     * User search result DataTable
     */
    {
        (function () {
            $('#table-user-search-result').DataTable({
                lengthMenu: [5, 10, 25, 100],
                'fnCreatedRow': function (tr, _, __) {
                    let guid = $(tr).attr('id');

                    $(`#mark-user-as-reviewed-${guid}`)
                        .click(function () {
                            $.post({
                                url: $(this).data('url'),
                                success: function () {
                                    $(`#mark-user-as-reviewed-${guid}`).hide();
                                }
                            });
                        })
                        .hover(
                            function () {
                                $(`#mark-user-as-reviewed-${guid}`).css('opacity', 1);
                            }, function () {
                                $(`#mark-user-as-reviewed-${guid}`).css('opacity', .5);
                            }
                        );

                    $(`#mark-user-shortname-as-safe-${guid}`).click(function () {
                        $.post({
                            url: $(this).data('url'),
                            success: function () {
                                $(`#user-shortname-${guid}`).removeClass('text-danger');
                                $(`#user-shortname-bad-behaviour-icon-${guid}`).hide();
                                $(`#mark-user-shortname-as-unsafe-${guid}`).show();
                                $(`#mark-user-shortname-as-safe-${guid}`).hide();
                            }
                        });
                    }).hover(
                        function () {
                            $(`#mark-user-shortname-as-safe-${guid}`).css('opacity', 1);
                        }, function () {
                            $(`#mark-user-shortname-as-safe-${guid}`).css('opacity', .5);
                        }
                    );

                    $(`#mark-user-shortname-as-unsafe-${guid}`).click(function () {
                        $.post({
                            url: $(this).data('url'),
                            success: function () {
                                $(`#user-shortname-${guid}`).addClass('text-danger');
                                $(`#user-shortname-bad-behaviour-icon-${guid}`).show();
                                $(`#mark-user-shortname-as-unsafe-${guid}`).hide();
                                $(`#mark-user-shortname-as-safe-${guid}`).show();
                            }
                        });
                    }).hover(
                        function () {
                            $(`#mark-user-shortname-as-unsafe-${guid}`).css('opacity', 1);
                        }, function () {
                            $(`#mark-user-shortname-as-unsafe-${guid}`).css('opacity', .5);
                        }
                    );

                    $(`#td-user-shortname-${guid}`).hover(
                        function () {
                            $(`#mark-user-as-reviewed-${guid}`).css('opacity', .5);
                            $(`#mark-user-shortname-as-safe-${guid}`).css('opacity', .5);
                            $(`#mark-user-shortname-as-unsafe-${guid}`).css('opacity', .5);
                        }, function () {
                            $(`#mark-user-as-reviewed-${guid}`).css('opacity', .15);
                            $(`#mark-user-shortname-as-safe-${guid}`).css('opacity', .15);
                            $(`#mark-user-shortname-as-unsafe-${guid}`).css('opacity', .15);
                        }
                    );
                }
            });
        })();
    }

    /*
     * Team search result DataTable
     */
    {
        (function () {
            $('#table-team-search-result').DataTable({
                lengthMenu: [5, 10, 25, 100]
            });
        })();
    }

    /*
     * Malleable items (edit in place)
     */
    {
        (function () {
            // Score edition in user details
            new Malle({
                fun: (value, original) => {
                    value = value === '' ? 0 : value;

                    let url = $(original).data('url');
                    if (url.includes('?')) {
                        url += '&score=' + value;
                    } else {
                        url += '?score=' + value;
                    }
                    window.location.href = url;

                    return value;
                },
                listenOn: '.data-malleable-user-score',
                inputClasses: ['form-control', 'form-control-user', 'malleable-input-num'],
                onBlur: Action.Cancel,
                submit: 'Update',
                submitClasses: ['btn', 'btn-primary', 'btn-sm', 'm-1'],
                cancel: 'Cancel',
                cancelClasses: ['btn', 'btn-warning', 'btn-sm', 'm-1'],
                tooltip: 'Click to edit',
            }).listen();

            // User name edition in user details
            let userNameBeforeUpdate;
            new Malle({
                before: (original) => {
                    userNameBeforeUpdate = $(original).text();
                    return true;
                },
                fun: (value, original) => {
                    // User name size must be between 2 and 100 (both inclusive)
                    if (value.length < 2 || value.length > 100) {
                        return userNameBeforeUpdate;
                    }

                    let url = $(original).data('url');
                    if (url.includes('?')) {
                        url += '&userName=' + value;
                    } else {
                        url += '?userName=' + value;
                    }
                    window.location.href = url;

                    return value;
                },
                listenOn: '.data-malleable-user-name',
                inputClasses: ['form-control', 'form-control-user'],
                onBlur: Action.Cancel,
                submit: 'Update',
                submitClasses: ['btn', 'btn-primary', 'btn-sm', 'm-1'],
                cancel: 'Cancel',
                cancelClasses: ['btn', 'btn-warning', 'btn-sm', 'm-1'],
                tooltip: 'Click to edit',
            }).listen();

            // Team name edition in team details
            let teamNameBeforeUpdate;
            new Malle({
                before: (original) => {
                    teamNameBeforeUpdate = $(original).text();
                    return true;
                },
                fun: (value, original) => {
                    // Team name size must be between 3 and 15 (both inclusive)
                    if (value.length < 3 || value.length > 15) {
                        return teamNameBeforeUpdate;
                    }

                    let url = $(original).data('url');
                    if (url.includes('?')) {
                        url += '&teamName=' + value;
                    } else {
                        url += '?teamName=' + value;
                    }
                    window.location.href = url;

                    return value;
                },
                listenOn: '.data-malleable-team-name',
                inputClasses: ['form-control', 'form-control-team'],
                onBlur: Action.Cancel,
                submit: 'Update',
                submitClasses: ['btn', 'btn-primary', 'btn-sm', 'm-1'],
                cancel: 'Cancel',
                cancelClasses: ['btn', 'btn-warning', 'btn-sm', 'm-1'],
                tooltip: 'Click to edit',
            }).listen();

            // Team description edition in team details
            let teamDescriptionBeforeUpdate;
            new Malle({
                before: (original) => {
                    teamDescriptionBeforeUpdate = $(original).text();
                    return true;
                },
                fun: (value, original) => {
                    // Team name size must be between 0 and 15 (both inclusive)
                    if (value.length > 50) {
                        return teamDescriptionBeforeUpdate;
                    }

                    let url = $(original).data('url');
                    if (url.includes('?')) {
                        url += '&teamDescription=' + value;
                    } else {
                        url += '?teamDescription=' + value;
                    }
                    window.location.href = url;

                    return value;
                },
                listenOn: '.data-malleable-team-description',
                inputClasses: ['form-control', 'form-control-description'],
                onBlur: Action.Cancel,
                submit: 'Update',
                submitClasses: ['btn', 'btn-primary', 'btn-sm', 'm-1'],
                cancel: 'Cancel',
                cancelClasses: ['btn', 'btn-warning', 'btn-sm', 'm-1'],
                tooltip: 'Click to edit',
            }).listen();
        })();
    }

    /*
     * Keep scroll position after form submits
     */
    {
        (function () {
            document.addEventListener('DOMContentLoaded', function () {
                let scrollPos = sessionStorage.getItem('scrollPos');
                if (scrollPos) {
                    window.scrollTo(0, parseInt(scrollPos));
                    sessionStorage.removeItem('scrollPos');
                }
            });

            window.addEventListener('beforeunload', function () {
                sessionStorage.setItem('scrollPos', window.scrollY.toString());
            });
        }());
    }

    /*
     * Left sidebar current page highlight
     */
    {
        (function () {
            let activeControllerName = $('navigation-controller-name').attr('name');
            $('[data-navigation-controller-name]').removeClass('active');
            $(`[data-navigation-controller-name="${activeControllerName}"]`).addClass('active');
        }());
    }

    /*
     * Current UTC time display
     */
    {
        (function () {
            function updateUtcTime() {
                let date = new Date();
                let hour = date.getUTCHours();
                let minutes = date.getUTCMinutes();
                let seconds = date.getUTCSeconds();

                $('#current-utc-time')
                    .html(
                        (hour < 10 ? '0' + hour : hour) +
                        ':' +
                        (minutes < 10 ? '0' + minutes : minutes) +
                        ':' +
                        (seconds < 10 ? '0' + seconds : seconds)
                    );
                $('#current-utc-time-container').removeClass('d-none');
            }

            updateUtcTime();

            $(document)
                .ready(function () {
                    setInterval(updateUtcTime, 1000);
                });
        }());
    }

    /*
     * User details iframe
     */
    {
        (function () {
            $('body').on('click', '[data-open-user-details-iframe]', function () {
                $('#loading').css('display', 'block');
                $('#user-details-iframe')
                    .on('load', function () {
                        $('#loading').css('display', 'none');
                        $(this)
                            .height($(this).contents().height())
                            .contents().find('html')
                            .css('overflow-y', 'hidden')
                            .css('overflow-x', 'auto');
                    })
                    .attr('src', $(this).attr('data-open-user-details-iframe'))
                    .show();
            })
        })();
    }

    /*
     * Team details iframe
     */
    {
        (function () {
            $('body').on('click', '[data-open-team-details-iframe]', function () {
                $('#loading').css('display', 'block');
                $('#team-details-iframe')
                    .on('load', function () {
                        $('#loading').css('display', 'none');
                        $(this)
                            .height($(this).contents().height())
                            .contents().find('html')
                            .css('overflow-y', 'hidden')
                            .css('overflow-x', 'auto');
                    })
                    .attr('src', $(this).attr('data-open-team-details-iframe'))
                    .show();
            })
        })();
    }

    /*
     * Displays and highlights period configuration save button when any field has changed
     */
    {
        (function () {
            function showPeriodSaveButton(button) {
                $(button).clearQueue().fadeTo(300, .25, function () {
                    $(button).clearQueue().fadeTo(300, 1, function () {
                        showPeriodSaveButton(button);
                    });
                });
            }

            $('form[data-show-period-save-button-on-change="true"]').each(function () {
                let formId = $(this).attr('id');
                $(`[form="${formId}"]`).each(function () {
                    $(this).change(function () {
                        let submitButton = $(`[form="${formId}"][type="submit"]`);

                        if ($(submitButton).attr('data-highlighted')) {
                            return;
                        }

                        $(submitButton).removeClass('d-none').attr('data-highlighted', 'true');
                        $(`#tr-${formId}`).addClass('period-pending-changes');

                        showPeriodSaveButton(submitButton);
                    });
                });
            });
        })();
    }

    /*
     * Reward items management
     */
    {
        $('#add-reward-item').on('click', function () {
            let countElem = $('#add-reward-item-count');
            let valueElem = $('#add-reward-item-value');
            let count = $(countElem).val() || 0;
            let value = $(valueElem).val() || '';
            let table = $(this).closest('table');
            let form = $(this).closest('form');

            for (let i = 0; i < count; ++i) {
                let tableRowCount = $(table).find('tr').length - 1;
                let tableLastRow = $(table).find('tr:nth-last-child(2)');
                let newRow = tableLastRow.clone();

                $(newRow)
                    .find('.add-reward-item-rank-label')
                    .html(tableRowCount);

                $(newRow)
                    .find('.add-reward-item-rank-input')
                    .attr('id', `Rewards_${tableRowCount - 1}__Rank`)
                    .attr('name', `Rewards[${tableRowCount - 1}].Rank`)
                    .attr('value', tableRowCount);

                $(newRow)
                    .find('.add-reward-item-reward-input')
                    .attr('id', `Rewards_${tableRowCount - 1}__Reward`)
                    .attr('name', `Rewards[${tableRowCount - 1}].Reward`)
                    .removeClass('valid')
                    .removeClass('input-validation-error')
                    .val(value);

                $(newRow)
                    .find('.add-reward-item-reward-validation')
                    .attr('data-valmsg-for', `Rewards[${tableRowCount - 1}].Reward`)
                    .removeClass('field-validation-valid')
                    .removeClass('field-validation-error')
                    .empty();

                tableLastRow.after(newRow);
            }

            $(form)
                .removeData('validator')
                .removeData('unobtrusiveValidation');

            $.validator
                .unobtrusive
                .parse(form);
        });

        $(".remove-reward-item").on('click', function () {
            let table = $(this).closest('table');
            let form = $(this).closest('form');
            let tableRowCount = $(table).find('tr').length - 1;

            if (tableRowCount <= 2) {
                return;
            }

            let tableLastRow = $(table).find('tr:nth-last-child(2)');
            $(tableLastRow).remove();

            $(form)
                .removeData('validator')
                .removeData('unobtrusiveValidation');

            $.validator
                .unobtrusive
                .parse(form);
        });
    }

    /*
     * Season periods management
     */
    {
        $('#add-season-period-days-after').on('click', function () {
            let table = $(this).closest('table');
            let form = $(this).closest('form');

            let tableRowCount = $(table).find('tr').length - 1;
            let tableLastRow = $(table).find('tr:nth-last-child(2)');
            let newRow = tableLastRow.clone();

            // Period local index in array
            $(newRow)
                .find('.add-season-period-index')
                .html(tableRowCount);

            // Start date
            $(newRow)
                .find('.add-season-period-start-date-input')
                .attr('id', `Periods_${tableRowCount - 1}__StartDate`)
                .attr('name', `Periods[${tableRowCount - 1}].StartDate`)
                .attr('value', '');

            $(newRow)
                .find('.add-season-period-start-date-input-validation')
                .attr('data-valmsg-for', `Periods[${tableRowCount - 1}].StartDate`);

            // End date
            $(newRow)
                .find('.add-season-period-end-date-input')
                .attr('id', `Periods_${tableRowCount - 1}__EndDate`)
                .attr('name', `Periods[${tableRowCount - 1}].EndDate`)
                .attr('value', '');

            $(newRow)
                .find('.add-season-period-end-date-input-validation')
                .attr('data-valmsg-for', `Periods[${tableRowCount - 1}].EndDate`);

            // Reward id
            $(newRow)
                .find('.add-season-period-reward-id-input')
                .attr('id', `Periods_${tableRowCount - 1}__RewardId`)
                .attr('name', `Periods[${tableRowCount - 1}].RewardId`)
                .attr('value', $(tableLastRow).find('.add-season-period-reward-id-input').val());
            $(newRow)
                .find('.add-season-period-reward-id-input-validation')
                .attr('data-valmsg-for', `Periods[${tableRowCount - 1}].RewardId`);

            // Localization key
            $(newRow)
                .find('.add-season-period-localization-key-input')
                .attr('id', `Periods_${tableRowCount - 1}__LocalizationKey`)
                .attr('name', `Periods[${tableRowCount - 1}].LocalizationKey`)
                .attr('value', $(tableLastRow).find('.add-season-period-localization-key-input').val());

            tableLastRow.after(newRow);

            $(form)
                .removeData('validator')
                .removeData('unobtrusiveValidation');

            $.validator
                .unobtrusive
                .parse(form);
        })

        $(".remove-season-period").on('click', function () {
            let table = $(this).closest('table');
            let form = $(this).closest('form');
            let tableRowCount = $(table).find('tr').length - 1;

            if (tableRowCount <= 2) {
                return;
            }

            let tableLastRow = $(table).find('tr:nth-last-child(2)');
            $(tableLastRow).remove();

            $(form)
                .removeData('validator')
                .removeData('unobtrusiveValidation');

            $.validator
                .unobtrusive
                .parse(form);
        });
    }

    /*
     * Leaderboard leagues management
     */
    {
        const defaultLeaderboardType = $('#leaderboard-league-leaderboard-type-default').val();
        const individualLeaderboardType = $('#leaderboard-league-leaderboard-type-individual').val();
        const teamLeaderboardType = $('#leaderboard-league-leaderboard-type-team').val();

        const defaultLeagueDistributionType = $('#leaderboard-league-distribution-type-default').val();
        const uniqueLeagueDistributionType = $('#leaderboard-league-distribution-type-unique-league').val();
        const byLevelLeagueDistributionType = $('#leaderboard-league-distribution-type-by-level').val();

        const leagueLeaderboardType = $('#leaderboard-league-leaderboard-type');
        const leagueDistributionType = $('#leaderboard-league-distribution-type');
        const addRemoveLeagueSection = $('#add-remove-leaderboard-league');

        const addButton = $('#add-leaderboard-league-item');

        $(leagueLeaderboardType).on('change', updateLeagueLeaderboardType);
        $(leagueDistributionType).on('change', updateLeagueDistributionType);

        function updateLeagueLeaderboardType() {
            let form = $(addButton).closest('form');

            switch ($(leagueLeaderboardType).val()) {
                case defaultLeaderboardType:
                case teamLeaderboardType:
                    $(leagueDistributionType).children('option').each(function () {
                        $(this).removeAttr('disabled');
                    });
                    break;

                case individualLeaderboardType:
                    if ($(leagueDistributionType).val() === byLevelLeagueDistributionType) {
                        $(leagueDistributionType).val(defaultLeagueDistributionType);
                    }
                    $(leagueDistributionType).children(`option[value=${byLevelLeagueDistributionType}]`).prop('disabled', 'disabled');
                    break;
            }

            $(form)
                .removeData('validator')
                .removeData('unobtrusiveValidation');

            $.validator
                .unobtrusive
                .parse(form);
        }

        function updateLeagueDistributionType() {
            let form = $(addButton).closest('form');

            switch ($(leagueDistributionType).val()) {
                case defaultLeagueDistributionType:
                case uniqueLeagueDistributionType:
                    $(addRemoveLeagueSection).hide();

                    let table = $(addButton).closest('table');
                    let tableRowCount = $(table).find('tr').length - 1;

                    while (tableRowCount > 1) {
                        let tableLastRow = $(table).find('tr:nth-last-child(2)');
                        $(tableLastRow).remove();
                        tableRowCount = $(table).find('tr').length - 1;
                    }
                    break;

                case byLevelLeagueDistributionType:
                    if ($(leagueLeaderboardType).val() === individualLeaderboardType) {
                        $(leagueLeaderboardType).val(defaultLeaderboardType);
                    }
                    $(addRemoveLeagueSection).show();
                    break;
            }

            $(form)
                .removeData('validator')
                .removeData('unobtrusiveValidation');

            $.validator
                .unobtrusive
                .parse(form);
        }

        $(addButton).on('click', function () {
            let table = $(this).closest('table');
            let form = $(this).closest('form');
            let tableRowCount = $(table).find('tr').length - 1;
            let tableLastRow = $(table).find('tr:nth-last-child(2)');
            let newRow = tableLastRow.clone();

            $(newRow)
                .find('.add-leaderboard-league-item-select')
                .attr('id', `Leagues_${tableRowCount}__LeagueId`)
                .attr('name', `Leagues[${tableRowCount}].LeagueId`)
                .removeClass('valid')
                .removeClass('input-validation-error')
                .val(defaultLeagueDistributionType);

            $(newRow)
                .find('.add-leaderboard-league-item-validation')
                .attr('data-valmsg-for', `Leagues[${tableRowCount}].LeagueId`)
                .removeClass('field-validation-valid')
                .removeClass('field-validation-error')
                .empty();

            tableLastRow.after(newRow);

            $(form)
                .removeData('validator')
                .removeData('unobtrusiveValidation');

            $.validator
                .unobtrusive
                .parse(form);
        });

        $(".remove-leaderboard-league-item").on('click', function () {
            let table = $(this).closest('table');
            let form = $(this).closest('form');
            let tableRowCount = $(table).find('tr').length - 1;

            if (tableRowCount <= 1) {
                return;
            }

            let tableLastRow = $(table).find('tr:nth-last-child(2)');
            $(tableLastRow).remove();

            $(form)
                .removeData('validator')
                .removeData('unobtrusiveValidation');

            $.validator
                .unobtrusive
                .parse(form);
        });
    }

    /*
     * Season stats team member list collapse/expand all
     */
    {
        $('#team-member-scores-expand-button').on('click', function () {
            $('.team-member-scores')
                .each(function () {
                    let listId = $(this).attr('href');

                    $(this).removeClass('collapsed');
                    $(listId).removeClass('collapsing');
                    $(listId).addClass('collapse');
                    $(listId).addClass('show');
                });

        });

        $('#team-member-scores-collapse-button').on('click', function () {
            $('.team-member-scores')
                .each(function () {
                    let listId = $(this).attr('href');

                    $(this).addClass('collapsed');
                    $(listId).removeClass('collapsing');
                    $(listId).addClass('collapse');
                    $(listId).removeClass('show');
                });
        });
    }

    /*
     * Update settings 
     */
    {
        $('[data-update-setting-id]')
            .on('click', function () {
                    let key = $(this).attr('data-update-setting-id');
                    let item = $(`#${key}`);
                    let inputType = item.attr('type');
                    let value = inputType === 'checkbox' ? item.is(':checked') : item.val();
                    let result = $(`#${key}-result`);
                    let url = item.attr('data-url');

                    $(result).empty();
                    $('#loading').css('display', 'block');
                    $(result).css('display', 'none');
                    $.post({
                        url: url,
                        data: {key: key, value: value},
                        success: function (data) {
                            $('#loading').css('display', 'none');
                            $(result)
                                .css('display', 'block')
                                .removeClass('text-danger')
                                .addClass('text-success')
                                .html(data.message);
                        },
                        error: function (data) {
                            $('#loading').css('display', 'none');
                            $(result)
                                .css('display', 'block')
                                .removeClass('text-success')
                                .addClass('text-danger')
                                .html(data["responseJSON"].detail);
                        }
                    });
                }
            );
    }

    /*
     * Collapse/expand left navigation links
     */
    {
        let navLinks = $('.nav-link');

        navLinks.on('click', function () {
            let dataTargetId = $(this).attr('data-target');
            let state = localStorage.getItem(dataTargetId);
            let collapsed = state === 'collapsed';

            if (collapsed) {
                localStorage.setItem(dataTargetId, 'expanded');
            } else {
                localStorage.setItem(dataTargetId, 'collapsed');
            }
        });

        navLinks.each(function () {
            let dataTargetId = $(this).attr('data-target');
            let state = localStorage.getItem(dataTargetId);
            let collapsed = state === 'collapsed';

            if (collapsed) {
                $(this).addClass('collapsed');
                $(`${dataTargetId}`).removeClass('show');
            } else {
                $(this).removeClass('collapsed');
                $(`${dataTargetId}`).addClass('show');
            }
        });
    }

    /*
     * Range input display value
     */
    {
        $('input[type="range"]').on('input', function () {
            let value = $(this).val();
            let display = $(this).attr('data-range-value');
            let suffix = $(this).attr('data-range-suffix');

            $(`#${display}`).html(`${value}${suffix}`);
        });
    }

    /*
     * Cross promo schedule forms
     */
    {
        $('#form-create-cross-promo-schedule, #form-update-cross-promo-schedule').on('submit', function () {
            let errorSpan = $('#form-cross-promo-schedule-error');
            errorSpan.empty();

            let startDate = $('#form-cross-promo-schedule-start-date').val();
            let endDate = $('#form-cross-promo-schedule-end-date').val();

            if (startDate >= endDate) {
                $('#loading').css('display', 'none');
                errorSpan.text('End date must be greater than start date');
                return false;
            }

            return true;
        });
    }

    /*
     * Event schedule forms
     */
    {
        $('#form-create-event, #form-update-event').on('submit', function () {
            let errorSpan = $('#form-event-error');
            errorSpan.empty();
            debugger;

            let startDate = $('#form-event-start-date').val();
            let endDate = $('#form-event-end-date').val();

            if (startDate >= endDate) {
                $('#loading').css('display', 'none');
                errorSpan.text('End date must be greater than start date');
                return false;
            }
        });
    }

    /*
     * Job history show more button
     */
    {
        $('#job-show-more').click(function () {
            $.post({
                url: $(this).data('url'),
                data: {startIndex: $('#job-history-start-index').val()},
                success: function (data) {
                    $('#table-history tbody').append(data);
                    window.setHistoryStartIndex = window.setHistoryStartIndex || {};
                    window.setHistoryStartIndex();
                }
            });
        });
    }

    /*
     * User admin message forms
     */
    {
        (function () {
            let messageIdSelect = $('#admin-message-id-select');
            let displayText = $('#user-admin-message-display-text');
            let valueText = $('#user-admin-message-value');
            let addButton = $('#user-admin-message-add-button');
            let updateButton = $('#user-admin-message-update-button');
            let error = $('#user-admin-message-error');

            $('.edit-user-admin-message-button').on('click', function () {
                $.get({
                    url: $(this).data('url'),
                    success: function (data) {
                        $('#user-admin-message-id').val(data["id"]);
                        messageIdSelect.val(data["messageId"]);
                        valueText.val(data["value"]);
                        displayText.val(data["displayText"]);

                        $('#form-add-or-update-user-admin-message').attr('id', '');
                        $('#form-update-user-admin-message').attr('id', 'form-add-or-update-user-admin-message');

                        addButton.addClass('d-none');
                        updateButton.removeClass('d-none');
                        $('#user-message-add-or-edit-title').text(`Edit message #${data["id"]}`);

                        document.querySelector('#user-message-add-or-edit-table').scrollIntoView({
                            behavior: 'smooth'
                        });
                        $('#user-message-add-or-edit-table').fadeOut(200).fadeIn(200).fadeOut(200).fadeIn(200);

                        refreshUserAdminMessageForm(false);
                    }
                });
            });

            messageIdSelect.on('change', function () {
                refreshUserAdminMessageForm();

                let selected = messageIdSelect.find('option:selected');
                let messageId = $(selected).val();
                let defaultValue = $(`#admin-message-default-value-${messageId}`).text();
                let description = $(`#admin-message-description-${messageId}`).val();

                valueText.val(defaultValue);
                $('#user-admin-message-description').text(description);
            });

            displayText.on('input', function () {
                refreshUserAdminMessageForm();
            });

            function refreshUserAdminMessageForm(showError = true) {
                let messageId = messageIdSelect.val();
                let messageIdVal = parseInt(messageId);
                if (isNaN(messageIdVal) || messageIdVal <= 0) {
                    addButton.prop('disabled', true);
                    updateButton.prop('disabled', true);
                    showError && error.removeClass('d-none');
                    return;
                }

                addButton.prop('disabled', false);
                updateButton.prop('disabled', false);
                showError && error.addClass('d-none');
            }

            refreshUserAdminMessageForm(false);
        })();
    }

})(jQuery);
